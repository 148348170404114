<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import Swal from "sweetalert2";
    import {required} from "vuelidate/lib/validators";

    /**
     * Calendar component
     */
    export default {
        name: "blog",

        page: {
            title: "标签管理",
            meta: [{ name: "description" }],
        },
        components: { Layout, PageHeader },
        data() {
            return {
                title: "标签管理",
                modalTitle: '新建标签',
                submitform: false,
                form: {
                    id:'',
                    tagName:'',
                },
                tagList:[],
            }
        },
        validations: {
            form: {
                tagName: { required }
            },

        },
        methods: {
            removeTag(col) {
                Swal.fire({
                    text: "此操作将永久删除该标签, 是否继续!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(result => {
                    if (result.value) {
                        if (this.deleteTag(col.id)) {
                            Swal.fire("Deleted!", "Your file has been deleted.", "success");
                        }
                    }
                });
            },
            addTag(){
                this.modalTitle = "新建标签";
                this.$bvModal.show("modal-scrollable")
            },
            editTag(col){
                this.form.tagName = col.tagName;
                this.form.id = col.id;
                this.modalTitle = "编辑标签";
                this.$bvModal.show("modal-scrollable")
            },
            async onSubmit(evt) {
                evt.preventDefault()
                if (this.$v.$invalid) {
                    return;
                } else {
                    let url = '/tag/insert';
                    if (this.form.id != '') {
                        url = '/tag/update';
                    }
                    const {data: res} = await this.$blog.post(url,this.form )
                    if (res.code !== 200) {
                        this.makeToast('danger',res.msg)
                        return null
                    } else  {
                        this.resetForm();
                        this.makeToast('success',res.msg)
                        this.$bvModal.hide("modal-scrollable");
                        this.submitform = false;
                        this.getFullTagList();
                        return res.data
                    }

                }
            },
            onReset(evt) {
                evt.preventDefault()
                // Reset our form values
                this.form.tagName = '';
                this.$bvModal.hide("modal-scrollable");
                this.submitform = false;
            },

            tagForm() {
                this.submitform = true;
                this.$v.$touch();
            },
            // 得到所有的分类
            async getFullTagList() {
                const {data: res} = await this.$blog.get('/tag/getall')
                this.tagList = res.data
            },
            async deleteTag (id){
                const {data: res} = await this.$blog.post('/tag/delete/'+id)
                if (res.code == '200') {
                    this.getFullTagList();
                    return true;
                }else{
                    return  false;
                }

            },
            makeToast(variant = null,msg = null) {
                this.$bvToast.toast(msg, {
                    title: variant,
                    variant: variant,
                    solid: true
                });
            },
            resetForm(){
                this.form = {
                    tagName: '',
                    id:'',
                }
            }

        },
        created() {
            this.getFullTagList()
        },
    };
</script>

<template>
    <Layout  style="background-color: #e9ecef;height: 1000px">
        <PageHeader :title="title" />
        <hr style="margin-top: -1rem;height: 1px;"/>
        <div style="background-color: white;min-height: 200px;max-height: 800px;overflow:auto">
            <div class="col-md-12" style="text-align:center"  >
                <b-button style="margin-top:20px"  variant="primary" @click="addTag">新建标签</b-button>
            </div>
            <div class="row" style="margin-top:20px;margin-left: 0px;width: 100%"
            >
                <div class="col-md-3" v-for="tag in tagList" :key="tag.id">
                    <b-card >
                        <b-card-text>
                            <div class="op" style="position: absolute;right: 5px;top: 5px;">
                                <i class="ri-edit-line" style="color: #3a8ee6;margin-right: 5px" @click="editTag(tag)"></i>
                                <i class="ri-delete-bin-6-line" style="color: red" @click="removeTag(tag)"></i>
                            </div>
                            <div><b>标签名称 <span style="color: #3a8ee6">{{tag.tagName}}</span></b></div> <br>
                            <div >博客数量 <span style="color: #2ac06d">{{tag.blogCount == undefined?0:tag.blogCount}}</span></div>
                        </b-card-text>
                    </b-card>

                </div>
            </div>
            <!--新建弹窗-->
            <div class="col-sm-12 col-md-4 col-xl-10">
                <div class="my-12 text-center">
                    <b-modal
                            size="lg"
                            id="modal-scrollable"
                            scrollable
                            :title="modalTitle"
                            title-class="font-18" hide-footer>
                        <div class="col-12">
                            <form class="form-horizontal" role="form" @submit.prevent="tagForm" @submit="onSubmit" @reset="onReset">
                                <b-form-group
                                        label-for="text"
                                        label-cols-lg="2"
                                        label="标签名称" >
                                    <input
                                            id="validationTooltip03"
                                            v-model="form.tagName"
                                            type="text"
                                            class="form-control"
                                            :class="{ 'is-invalid': submitform && $v.form.tagName.$error }"
                                    />
                                    <div v-if="submitform && $v.form.tagName.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.tagName.required">请填写标签名称</span>
                                    </div>
                                </b-form-group>
                                <div style="margin-top: 20px;float: right;padding: 20px 20px">
                                    <b-button class="btn btn-default" style="background-color: gray;color: white;margin-right: 10px" type="reset">取消</b-button>
                                    <b-button  variant="primary" type="submit">保存</b-button>
                                </div>
                            </form>
                        </div>
                    </b-modal>
                </div>
            </div>


        </div>
    </Layout>
</template>
<style>

</style>